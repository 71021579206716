import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isAuth: false,
    user: null,
    msalInstance: null
};

export const msalInstanceSlice = createSlice({
  name: 'msalInstance',
  initialState,
  reducers: {
    setMsalInstance: (state, action) => {
        state.msalInstance = action.payload;
    },
    setAuthStatus: (state, action) => {
        state.isAuth = action.payload.authStatus;
    },
    setUser: (state, action) => {
        state.user = action.payload;
        const token = state.user.idTokenClaims?.sub || null;
        const displayName = state.user.name || null;
        const username = state.user.username || null;
        const email = state.user.username || null;
        const role = state.user.idTokenClaims?.roles || null;

        axios.post(`${process.env.REACT_APP_API_URL}/users/checkUser`, {token: token, displayName: displayName, username: username, email: email, role: role});

    }
  },
});

export const { setMsalInstance, setAuthStatus, setUser } = msalInstanceSlice.actions;

export default msalInstanceSlice.reducer;
