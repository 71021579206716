import axios from "axios";

const initialState = [];

export default function entriesReducer(state = initialState, action) {
  switch (action.type) {
    case "entries/entriesAdded": {
      return [...state, action.payload];
    }
    case "entries/entriesLoaded": {
      return action.payload;
    }
    case "entries/updateEntry": {
      return state.map((evt) =>
        evt.id === action.payload.data.id ? action.payload.data : evt
      );
    }
    case "entries/deleteEntry": {
      return state.filter((entry) => entry.id !== action.payload);
    }
    case "entries/entriesReset": {
      return [];
    }
    default:
      return state;
  }
}

export function fetchEntries(payload) {
  return async function fetchEntriesThunk(dispatch, getState) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/entries/${payload.username}`, {
        params: {
          startDate: payload.startDate,
          endDate: payload.endDate
        }
      }
    );

    const entries = response.data.entries;
    const groupedEntries = entries.reduce((acc, entry) => {
      const entryKey = `${entry.date}`;
      if (!acc[entryKey]) {
        acc[entryKey] = { ...entry }; 
      } else {
        // Sum properties of entries with the same date, rdc, and rdv

        acc[entryKey] = {
          ...acc[entryKey],
          rdc: (acc[entryKey].rdc || 0) + (entry.rdc || 0),
          rdv: (acc[entryKey].rdv || 0) + (entry.rdv || 0),
          nadure: (acc[entryKey].nadure || 0) + (entry.nadure || 0),
          koriscenje: (acc[entryKey].koriscenje || 0) + (entry.koriscenje || 0),
          drp: acc[entryKey].drp,
          bp8: entry.bp8 != undefined ? entry.bp8 : acc[entryKey].bp8,

          // Merge startTime and endTime
          startTime: entry.startTime != undefined ? entry.startTime : acc[entryKey].startTime,
          endTime: entry.endTime != undefined ? entry.endTime : acc[entryKey].endTime,
          lunchTime: entry.lunchTime != undefined ? entry.lunchTime : acc[entryKey].lunchTime,
          lunchTimeEnd: entry.lunchTimeEnd != undefined ? entry.lunchTimeEnd : acc[entryKey].lunchTimeEnd,
        };

      }
      return acc;
    }, {});

    const groupedEntriesArray = Object.values(groupedEntries);

    dispatch({ type: "entries/entriesLoaded", payload: groupedEntriesArray });
  };
}

export function resetEntries() {
  return function resetEntriesThunk(dispatch, getState) {
    dispatch({ type: "entries/entriesReset", payload: [] });
  };
}

export function updateEntry(payload) {
  return async function updateEntryThunk(dispatch, getState) {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/entries/${payload.username}`,
      payload
    );
    dispatch({ type: "entries/updateEntry", payload: response.data });
    dispatch(fetchEntries(payload.username));

  };
}

export function deleteEntry(payload) {
  return async function deleteEntryThunk(dispatch) {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/entries/${payload.selectedEvent.id}`
    );
    dispatch({
      type: "entries/deleteEntry",
      payload: payload.selectedEvent.id,
    });
  };
}

export function saveNewEntry(payload) {
  return async function saveNewEntryThunk(dispatch, getState) {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/entries",
      payload
    );

    dispatch({ type: "entries/entriesAdded", payload: response.data.entry });
    dispatch(fetchEntries(payload.username));
  };
}

export function addHolidayEntry(payload) {
  return async function addHolidayDayThunk(dispatch) {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/entries/holiday",
      payload
    );

    dispatch({ type: "entries/entriesAdded", payload: response.data.entry });
    dispatch(fetchEntries(payload.username));
  };
}

export function addSickdayEntry(payload) {
  return async function addHolidayDayThunk(dispatch) {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/entries/sickday",
      payload
    );

    dispatch({ type: "entries/entriesAdded", payload: response.data.entry });
    dispatch(fetchEntries(payload.username));
  };
}

export function addBusinessTripEntry(payload) {
  return async function addHolidayDayThunk(dispatch) {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/entries/business-trip",
      payload
    );

    dispatch({ type: "entries/entriesAdded", payload: response.data.entry });
    dispatch(fetchEntries(payload.username));
  };
}



export function addOvertimeUse(payload) {
  return async function addOvertimeUseThunk(dispatch) {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/entries/overtime-use",
      payload
    );

    dispatch({ type: "entries/entriesAdded", payload: response.data.entry });
    dispatch(fetchEntries(payload.username));
  };
}

export const selectAllEntries = (state) => state.entries;
