import React, { useEffect, useState } from "react";
import axios from "axios";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import sl from "date-fns/locale/sl"; // Slovenian locale
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Container, CircularProgress } from "@mui/material";
import "./VacationsCalendar.css"; // Create this CSS file for custom styles

const locales = {
  sl: sl,
};

const localizer = dateFnsLocalizer({
  format: (date, formatStr, options) =>
    format(date, formatStr, { locale: locales["sl"] }),
  parse: (date, formatStr, options) =>
    parse(date, formatStr, new Date(), { locale: locales["sl"] }),
  startOfWeek: (date, options) => startOfWeek(date, { locale: locales["sl"] }),
  getDay: (date, options) => getDay(date, { locale: locales["sl"] }),
  locales,
});

const messages = {
  allDay: "Cel dan",
  previous: "Prejšnji",
  next: "Naslednji",
  today: "Danes",
  month: "Mesec",
  week: "Teden",
  day: "Dan",
  agenda: "Dnevni red",
  date: "Datum",
  time: "Čas",
  event: "Dogodek",
  noEventsInRange: "Ni dogodkov v tem obdobju.",
  showMore: (total) => `+ (${total})`, // Custom message for "more"
};

const VacationsCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const today = new Date();
  const currentMonth = today.getMonth() + 1; // getMonth() returns 0-based month index, so we add 1
  const currentYear = today.getFullYear();

  const [vacations, setVacations] = useState([]);

  const fetchVacations = async (date) => {
    const month = date.getMonth() + 1; // getMonth() returns 0-based month index, so we add 1
    const year = date.getFullYear();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/entries/vacations`,
        {
          params: { month, year },
        }
      );
      setVacations(response.data);
    } catch (error) {
      console.error("Error fetching vacations:", error);
    } 
  };

  useEffect(() => {
    fetchVacations(today);
    console.log(currentDate);
  }, [currentMonth, currentYear]);

  const events = vacations.map((vacation) => ({
    title: `${vacation.displayName}`,
    type: `${new Date(vacation.startDate).toLocaleDateString(
      "sl-SI"
    )} - ${new Date(vacation.endDate).toLocaleDateString("sl-SI")} (${
      vacation.type
    })`,
    start: new Date(vacation.startDate),
    end: new Date(new Date(vacation.endDate).setHours(23, 59, 59)), // Ensure the end time is the end of the day
    allDay: true, // Ensure the event spans the entire day
  }));

  const handleNavigate = (date) => {
    setCurrentDate(date);
    fetchVacations(date);
  };

  return (
    <div>
      <h1 className="md:mr-10 pt-3 pb-3 text-xl md:text-xl text-gray-500 font-light text-left">
        Evidenca odsotnosti
      </h1>
      <div className="card">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600 }} // Increase the height for better scrollable view
          views={{ month: true, day: true }} // Only show Month and Day views
          defaultView={Views.MONTH}
          onNavigate={handleNavigate}
          eventPropGetter={(event) => ({
            style: { backgroundColor: "#DB0B33", color: "white" },
          })}
          components={{
            event: ({ event }) => (
              <span>
                <strong>{event.title}</strong>
              </span>
            ),
            month: {
              event: ({ event }) => (
                <div style={{ padding: "2px 5px", width: "80%" }}>
                  {event.title} {event.type}
                </div>
              ),
            },
            day: {
              event: ({ event }) => (
                <div style={{ padding: "2px 5px" }}>
                  <div>{event.title}</div>
                  <div>{event.type}</div>
                </div>
              ),
            },
          }}
          messages={{
            ...messages,
            month: "Mesec",
            day: "Dan",
          }}
          scrollToTime={new Date(1970, 1, 1, 8)} // Set default scroll to 8 AM
          step={30} // Set the time step in minutes
          timeslots={2} // Set the number of timeslots per hour
        />
      </div>
    </div>
  );
};

export default VacationsCalendar;
