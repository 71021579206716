import { useEffect, useState } from "react";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "@mui/material";

import {
  setMsalInstance,
  setAuthStatus,
  setUser,
} from "./store/redux/slices/msalInstanceSlice";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";

import "../src/App.css";
import classes from "../src/Auth.css";

const Auth = ({ children, msal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const authStatus = useSelector((state) => state.msalInstance.isAuth);
  const user = useSelector((state) => state.msalInstance.user);
  const msalInstance = useSelector((state) => state.msalInstance.msalInstance);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const accounts = msal.getAllAccounts();

    
    if (accounts.length !== 0) {
      dispatch(setUser(accounts[0]));
      dispatch(setAuthStatus({ authStatus: true }));
      dispatch(setMsalInstance(msal));
    } else {
      dispatch(setAuthStatus({ authStatus: false }));
    }
  }, [dispatch, msal]);

  useEffect(() => {
    const Test = async () => {
      await msal.initialize();
      await msal.handleRedirectPromise().then((authResponse) => {
        if (authResponse) {
          setAuthStatus(true);
          const accounts = msal.getAllAccounts();

          if (accounts.length > 0) {
            dispatch(setAuthStatus({ authStatus: true }));
            dispatch(setUser(accounts[0]));
            dispatch(setMsalInstance(msal));
          }
        }
      });
    };
    Test();
  }, [msal]);

  const login = async () => {
    try {
      localStorage.setItem('isAuthenticated', true);
      await msal.loginRedirect();

      const accounts = await msal.getAllAccounts();
      dispatch(setUser(accounts[0]));
      dispatch(setMsalInstance(msal));
    } catch (error) {
      setErrorMessage("An error occurred during login.");
      console.error(error);
    }
  };

  const logout = () => {
    msal.logoutPopup();

    dispatch(setUser(null));
    dispatch(setAuthStatus({ authStatus: false }));
  };

  return (
    <MsalProvider instance={msal}>
      {authStatus ? (
        <>{children}</>
      ) : (
        <div>
          <Container
            maxWidth="xl"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ padding: "20px" }} className="w-4/5 md:w-1/2">
              <section>
                <div className="text-center flex justify-center">
                  <img
                    className="logo"
                    src="https://www.termoshop.si/wp-content/uploads/2021/07/termoshop-30-let.png"
                  />
                </div>
                <div className="text-center flex justify-center">
                  <button
                    className="btn-primary btn text-white w-full md:w-1/2 mt-5"
                    onClick={login}
                  >
                    Prijava
                  </button>
                </div>
                {errorMessage && <p>{errorMessage}</p>}
              </section>
            </div>
          </Container>
        </div>
      )}
    </MsalProvider>
  );
};

export default Auth;
