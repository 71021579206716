import axios from "axios";

const initialState = [];

export default function usersReducer(state = initialState, action) {
    switch(action.type) {
        case 'users/usersLoaded': {
            return action.payload;
        }
        case 'users/userLoaded': {
            return action.payload;
        }
        default: 
            return state
        
    }
}

export async function fetchUsers(dispatch, getState) {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
    dispatch({type: 'users/usersLoaded', payload: response.data.users});
}

export const fetchUser = (payload) => async (dispatch) => { 
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${payload.username}`);
    dispatch({type: 'users/userLoaded', payload: response.data});
}

export const selectAllUsers = (state) => state.users;
export const selectedUser = (state) => state;