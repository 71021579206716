import { useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import Container from "@mui/material/Container";
import Box, { BoxProps } from "@mui/material/Box";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import { resetEntries } from "../../store/redux/slices/entriesSlice";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  setMsalInstance,
  setAuthStatus,
  setUser,
} from "../../store/redux/slices/msalInstanceSlice";

const Header = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();

  const msalInstance = useSelector((state) => state.msalInstance.msalInstance);
  const authStatus = useSelector((state) => state.msalInstance.isAuth);
  const user = useSelector((state) => state.msalInstance.user);
  const [userRole, setUserRole] = useState("Normal");
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    if (
      user.idTokenClaims.roles != undefined ||
      user.idTokenClaims.roles != null
    ) {
      const roles = user.idTokenClaims.roles;

      if (roles.includes("Admin")) {
        setUserRole("Admin");
      } else {
        setUserRole("Normal");
      }
    }
  });

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const logout = () => {
    msalInstance.logout();

    localStorage.removeItem("isAuthenticated");
    dispatch(setUser(null));
    dispatch(setAuthStatus({ authStatus: false }));
  };

  const MobileMenuButton = ({ onClick, menuVisible }) => (
    <button onClick={onClick} className="md:hidden mobile-menu-button">
      {menuVisible ? (
        <CloseIcon sx={{ color: "white" }} />
      ) : (
        <MenuIcon sx={{ color: "white" }} />
      )}
    </button>
  );

  return (
    <Box className="header bg-white" sx={{ boxShadow: 1 }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link to="/">
            <img
              className="logo"
              src="https://www.termoshop.si/wp-content/uploads/2021/07/termoshop-30-let.png"
            />
          </Link>
          <div className="flex items-center">
            {isLoggedIn && role == 1 && (
              <Link className="mr-4" to="/admin">
                Seznam zaposlenih
              </Link>
            )}

            {isLoggedIn && (
              <Link className="mr-4" to="/">
                <CalendarMonthIcon sx={{ color: "#303f46" }} />
              </Link>
            )}

            {authStatus && (
              <div className="pr-5 md:pr-0">
                {userRole == "Admin" && (
                  <Link className="mr-4 hidden md:inline" to="/admin">
                    Seznam zaposlenih
                  </Link>
                )}

                <Link className="mr-4 hidden md:inline" to="/odsotnost">
                  Evidenca odsotnosti
                </Link>

                <Link className="mr-4" to="/">
                  <HomeIcon sx={{ color: "#303f46" }} />
                </Link>

                <Link className="mr-4" to="/zgodovina">
                  <CalendarMonthIcon sx={{ color: "#303f46" }} />
                </Link>

                <button onClick={logout} className="btn-red hidden md:inline">
                  Odjava
                </button>
              </div>
            )}

            <MobileMenuButton onClick={toggleMenu} menuVisible={menuVisible} />
          </div>
        </Box>
      </Container>
      <div
        className={`mobile-menu ${
          menuVisible ? "block" : "hidden"
        } bg-white absolute w-full pt-10 pb-10 z-[99] shadow-md transition-transform ease-in-out duration-300 transform`}
      >
        <Container maxWidth="xl">
          {authStatus && (
            <div className="flex flex-col">
              <Link className="m-2 md:inline" to="/odsotnost">
                Evidenca odsotnosti
              </Link>

              {userRole == "Admin" && (
                <div className="m-2">
                  <Link to="/admin">Seznam zaposlenih</Link>
                </div>
              )}

              <div className="mt-3">
                <button onClick={logout} className="btn-red">
                  Odjava
                </button>
              </div>
            </div>
          )}
        </Container>
      </div>
    </Box>
  );
};
export default Header;
