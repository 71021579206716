import React, { useEffect } from "react";
import Day from "./Day";

export default function Month({ month }) {
  return (
    <>
      <div className=" lg:grid card flex-1 grid-cols-4 md:grid-cols-7 md:grid-rows-5">
        {month.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <Day day={day} key={idx} rowIdx={i} />
            ))}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
