import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import { fetchEntries } from "../../store/redux/slices/entriesSlice";
import { getWorkingDays } from "../../services/EntryService";

const StatusSidebar = () => {
  const [opravljeneUre, setOpravljeneUre] = useState(0);
  const [vseUre, setVseUre] = useState(0);
  const [nadure, setNadure] = useState(0);
  const [workingDays, setWorkingDays] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);

  const [lunchesToDisplay, setLunchesToDisplay] = useState(null);
  const [overtimeToDisplay, setOvertimeToDisplay] = useState(null);

  const [transportationsToDisplay, setTransportationsToDisplay] =
    useState(null);

  const { monthIndex, setMonthIndex } = useContext(GlobalContext);

  const [malice, setMalice] = useState(null);
  const [prevozi, setPrevozi] = useState(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.msalInstance.user);

  const year = dayjs(new Date(dayjs().year(), monthIndex)).format("YYYY");
  const monthNumber = getMonthNumber(
    dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM")
  );

  function getMonthNumber(monthName) {
    const months = {
      januar: 1,
      februar: 2,
      marec: 3,
      april: 4,
      maj: 5,
      junij: 6,
      julij: 7,
      avgust: 8,
      september: 9,
      oktober: 10,
      november: 11,
      december: 12,
    };

    return months[monthName];
  }

  function getFirstAndLastDateOfMonth(year, month) {
    // Helper function to format date to 'YYYY-MM-DD'
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Adjust month to 1-based index
      const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
      return `${year}-${month}-${day}`;
    };

    // Get first and last dates of the current month
    const firstDateCurrent = new Date(year, month, 1);
    const lastDateCurrent = new Date(year, month + 1, 0);

    // Get first and last dates of the previous month
    const firstDatePrevious = new Date(year, month - 2, 1);
    const lastDatePrevious = new Date(year, month, 0);

    // Get first and last dates of the next month
    const firstDateNext = new Date(year, month + 1, 1);
    const lastDateNext = new Date(year, month + 2, 0);

    return {
      current: {
        startDate: formatDate(firstDateCurrent),
        endDate: formatDate(lastDateCurrent),
      },
      previous: {
        startDate: formatDate(firstDatePrevious),
        endDate: formatDate(lastDatePrevious),
      },
      next: {
        startDate: formatDate(firstDateNext),
        endDate: formatDate(lastDateNext),
      },
    };
  }

  // useEffect(() => {
  //   dispatch(fetchEntries(user.username));
  //   getUser();
  // }, [monthIndex, dispatch]);

  useEffect(() => {
    async function fetchData() {
      let monthNumber = 0;
      let yearNumber = 0;

      setTimeout(() => {
        monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
        yearNumber = parseInt(localStorage.getItem("currentYear"));

        const { current, previous, next } = getFirstAndLastDateOfMonth(
          yearNumber,
          monthNumber
        );

        dispatch(
          fetchEntries({
            username: user.username,
            startDate: previous.startDate,
            endDate: next.endDate,
          })
        )
          .then(() => {
          })
          .catch((error) => {
            console.error("Error fetching entries:", error);
          });
      }, 1000);
      await getUser();
      await getEntries();
    }

    fetchData();
  }, [monthIndex]);

  useEffect(() => {
    getEntries();
  }, [currentUser]);

  useEffect(() => {
    const workingDaysCount = async () => {
      const res = await getWorkingDays(
        parseInt(localStorage.getItem("currentYear")),
        parseInt(localStorage.getItem("currentMonth"))
      );
      setWorkingDays(parseInt(res * 8));
    };

    workingDaysCount();
  }, []);

  useEffect(() => {
    checkUserStatus();
  }, [user.username, monthNumber, year, currentUser, nadure]);

  const checkUserStatus = async () => {

    const status = await axios
      .post(process.env.REACT_APP_API_URL + "/approval-hours", {
        username: user.username,
        month: monthNumber,
        year: year,
      })
      .then((response) => {
        if (response.data != null) {
          if (response.data.lunches !== undefined) {
            setLunchesToDisplay(response.data.lunches);
          }

          if (response.data.transportations !== undefined) {
            setTransportationsToDisplay(response.data.transportations);
          }

          if (response.data.overtimeHistory !== undefined) {
            setOvertimeToDisplay(response.data.overtimeHistory);
          }
        } else {
          if (currentUser != null) {
            const temp = currentUser.overtimeHours + nadure;
            setOvertimeToDisplay(temp);
          }
        }
      });
  };

  const getUser = async () => {
    const response = await axios
      .get(`${process.env.REACT_APP_API_URL}/users/${user.username}`)
      .then((body) => {
        setCurrentUser(body.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEntries = async () => {
    try {
      const monthNumber = parseInt(localStorage.getItem("currentMonth")) + 1;
      const yearNumber = parseInt(localStorage.getItem("currentYear"));

      let role = null;

      if (currentUser) {
        role = currentUser.role[0];
      }

      await checkUserStatus();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/entries/getByMonth`,
        {
          username: user.username,
          monthNumber: monthNumber,
          yearNumber: yearNumber,
          role: role,
        }
      );

      const data = response.data;

      setOpravljeneUre(data.opravljeneUre);
      setVseUre(data.vseUre);
      setNadure(data.nadure);

      // Check if Malice and Prevozi are received correctly
      if (data.malice !== undefined) {
        setLunchesToDisplay(null);
        setMalice(data.malice);
      }

      if (data.prevozi !== undefined) {
        setTransportationsToDisplay(null);
        setPrevozi(data.prevozi);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="card">
      <div className="flex flex-row justify-between">
        <p>Opravljene ure:</p>
        <p>{opravljeneUre}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p>Vse ure (fond):</p>
        <p>{parseInt(workingDays)}</p>
      </div>
      {malice !== null && (
        <div className="flex flex-row justify-between">
          <p>Malice:</p>
          <p>
            {lunchesToDisplay !== null ? lunchesToDisplay : parseInt(malice)}
          </p>
        </div>
      )}

      {prevozi !== null && (
        <div className="flex flex-row justify-between">
          <p>Prevozi:</p>
          <p>
            {transportationsToDisplay !== null
              ? transportationsToDisplay
              : parseInt(prevozi)}
          </p>
        </div>
      )}

      <div className="flex flex-row justify-between mt-5">
        <p>Nadure tekoči mesec:</p>
        <p>{nadure}</p>
      </div>

      {overtimeToDisplay != null && (
        <div className="flex flex-row justify-between">
          <p>Nadure skupaj:</p>
          <p>{overtimeToDisplay}</p>
        </div>
      )}
    </div>
  );
};
export default StatusSidebar;
